import {
  inject,
  Injectable,
  signal,
  Signal,
  WritableSignal,
} from '@angular/core';
import { HealthscribeRecorderService } from './healthscribe-recorder.service';
import { AppointmentRecordingQueryService } from '@app/features/summaries/components/summaries/appointment-recording-query.service';

type RecordingStep =
  | 'transcriptAvailable'
  | 'recording'
  | 'dismissing'
  | 'dismissed'
  | 'aboutToGenerate'
  | 'generating'
  | 'default';

@Injectable({
  providedIn: 'root',
})
export class RecordingStepService {
  private privateRecordingStep = signal<RecordingStep>('default');
  private recordingId = signal<string | null>(null);
  private recordingService = inject(HealthscribeRecorderService);
  private recordingQueryService = inject(AppointmentRecordingQueryService);

  constructor() {}
  get writableBannerState(): WritableSignal<RecordingStep> {
    return this.privateRecordingStep;
  }

  get bannerSignal(): Signal<RecordingStep> {
    return this.privateRecordingStep;
  }

  get latestAppointmentRecordingId(): Signal<string | null> {
    return this.recordingId;
  }
  private cancellationTimeout: NodeJS.Timeout | undefined;

  cancelEndRecording(): void {
    if (this.cancellationTimeout) {
      clearTimeout(this.cancellationTimeout);
      this.cancellationTimeout = undefined;
      this.privateRecordingStep.set('recording');
      this.recordingService.resumeRecording();
    }
  }

  async endRecording(): Promise<void> {
    this.privateRecordingStep.set('aboutToGenerate');
    await this.recordingService.pauseRecording();
    this.cancellationTimeout = setTimeout(() => {
      this.recordingQueryService.setAppointmentId(
        this.recordingService.appointmentRecordingId ?? null,
      );
      this.recordingService.endRecording();
      this.privateRecordingStep.set('generating');
    }, 5000);
  }

  cancelDismiss(): void {
    if (this.cancellationTimeout) {
      clearTimeout(this.cancellationTimeout);
      this.cancellationTimeout = undefined;
      this.privateRecordingStep.set('default');
    }
  }

  dismiss(): void {
    this.privateRecordingStep.set('dismissing');
    this.cancellationTimeout = setTimeout(() => {
      this.privateRecordingStep.set('dismissed');
    }, 5000);
  }
}
