<div>
  <div *ngIf="variant === 'instructional'"
       om-layout
       om-layout-align="space-between center"
       class="instructional banner-container">
    <div>Record and summarize visit</div>
    <div om-layout
         om-layout-align="end center">
      <button omgButton
              type="button"
              variant="primary"
              data-testid="start-recording-button"
              [disabled]="micPermission() === 'denied'"
              (click)="startRecording.emit($event)">
        Start Recording
      </button>
      <omg-icon-button icon="close"
                       library="om-icon"
                       (keypress)="dismiss.emit($event)"
                       (click)="dismiss.emit($event)"
                       data-testid="dismiss-button"></omg-icon-button>
    </div>
  </div>
  <div *ngIf="variant === 'dismissing'"
       om-layout
       om-layout-align="space-between center"
       class="dismissing banner-container">
    <div>HealthScribe dismissed</div>
    <button omgButton
            type="button"
            variant="flat"
            data-testid="undo-dismiss-healthscribe-button"
            (click)="undoDismiss.emit($event)">
      Undo
    </button>
  </div>
  <div *ngIf="variant === 'generating' || variant === 'aboutToGenerate'"
       om-layout
       om-layout-align="space-between center"
       class="dismissing banner-container">
    <div><i class="fa-regular fa-spinner fa-spin-pulse"></i> Generating Summary.</div>
    <button omgButton
            *ngIf="variant === 'aboutToGenerate'"
            type="button"
            variant="flat"
            data-testid="undo-dismiss-healthscribe-button"
            (click)="undoGenerate.emit($event)">
      Resume Recording
    </button>
  </div>
  <div *ngIf="variant === 'success'"
       om-layout
       om-layout-align="space-between center"
       class="success banner-container">
    <div>
      <i class="om-icon icon-magic"></i>
      <strong> Summary Ready</strong>
      <i>{{expiration}}</i>
    </div>
    <button omgButton
            type="button"
            variant="flat"
            data-testid="view-summary-button"
            (click)="viewSummary.emit($event)">
      View Summary
    </button>
  </div>
</div>
